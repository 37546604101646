/* eslint-disable @typescript-eslint/no-use-before-define */
(() => {
  const selectors = {
    component: ".myb-hero",
    mainCarousel: ".js-main-carousel .cmp-bhg-swiper-js",
    secondCarousel: ".js-second-carousel .cmp-bhg-swiper-js",
    animationCards: ".js-myb-animation-cards",
    animationCardsCard: ".js-myb-animation-cards-card",
    dynamicClass: ".c-myb-hero.dynamicLoading",
    isEdit: window.Granite && window.window.Granite.author ? true : false,
  };

  const MyBHero = () => {
    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-myBHero.css", selectors.dynamicClass);
    document.querySelectorAll(selectors.component).forEach(initSwipers);
  };

  const initSwipers = (component) => {
    const mainSwiperEl = component.querySelector(selectors.mainCarousel);
    const secondSwiperEl = component.querySelector(selectors.secondCarousel);

    if (!mainSwiperEl || !secondSwiperEl) {
      return;
    }

    //------------------------------- Second Swiper ------------------------------------//

    const secondSwiper = new window.Swiper(secondSwiperEl, {
      loop: true,
      allowTouchMove: false,
      effect: "fade",
      fadeEffect: { crossFade: true },
      speed: 1500,
    });

    bcl.u.swiper.changeParent(secondSwiperEl, component);

    //------------------------------- Main Swiper ------------------------------------//

    // First swiper autoplay options
    const autoplayOptions = {
      delay: 5000,
      disableOnInteraction: false,
    };

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const mainSwiper = new window.Swiper(mainSwiperEl, {
      loop: true,
      speed: 1000,
      autoplay: selectors.isEdit ? false : autoplayOptions,
      on: {
        init: (swiper) => {
          addNumberSlides(swiper);
          syncSwipers(swiper, secondSwiper);
          animateCards(swiper);
        },
        slideChangeTransitionStart: (swiper) => {
          syncSwipers(swiper, secondSwiper);
          animateCards(swiper);
        },
      },
    });

    bcl.u.swiper.changeParent(mainSwiperEl, component);

    //--------------------------------------------------------------------------------//
  };

  const addNumberSlides = (swiper) => {
    swiper.slides.forEach((slide) => {
      if (!slide.querySelector(".number-slide")) {
        const dataIndex = slide.getAttribute("data-swiper-slide-index");
        if (dataIndex !== null) {
          const numericIndex = parseInt(dataIndex, 10) + 1;
          const spanNumber = document.createElement("span");
          spanNumber.classList.add("number-slide");
          spanNumber.textContent = numericIndex < 10 ? `0${numericIndex}` : `${numericIndex}`;
          slide.appendChild(spanNumber);
        }
      }
    });
  };

  const syncSwipers = (mainSwiper, secondSwiper) => {
    const prevIndex = mainSwiper.realIndex === 0 ? mainSwiper.slides.length - 1 : mainSwiper.realIndex - 1;
    secondSwiper.slideToLoop(prevIndex);
  };

  const animateCards = (swiper) => {
    swiper.slides.forEach((slide) => {
      const animationContainer = slide.querySelector(selectors.animationCards);
      if (!animationContainer) {
        return;
      }

      const cards = animationContainer.querySelectorAll(selectors.animationCardsCard);
      if (slide.classList.contains("swiper-slide-active")) {
        cards.forEach((card, index) => {
          setTimeout(() => {
            card.classList.add("mod--display-card");
          }, index * 200);
        });
      } else {
        cards.forEach((card) => card.classList.remove("mod--display-card"));
      }
    });
  };

  if (document.readyState !== "loading") {
    MyBHero();
  } else {
    document.addEventListener("DOMContentLoaded", MyBHero);
  }
})();
