(() => {
  const SELECTORS = {
    dynamicClass: ".c-myb-benefit.dynamicLoading",
    component: ".myb-benefit",
    content: ".js-myb-benefit",
    buttonMore: ".js-myb-benefit-more",
    buttonLess: ".js-myb-benefit-less",
    swiperComponent: ".js-swiper-myb-benefit",
    levels: {
      essential: "1",
      special: "2",
      unique: "3",
    },
  };

  const MybBenefit = function () {
    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybBenefit.css", SELECTORS.dynamicClass);
    const parentElements = document.querySelectorAll(SELECTORS.component);
    if (!parentElements.length) {
      return;
    }
    const userData = bcl.profile?.mapValues?.data || {};
    const level = userData.level ? bcl.u.getKeyByValue(SELECTORS.levels, userData.level) : null;
    parentElements.forEach((parent) => {
      const content = parent.querySelector(SELECTORS.content);
      if (content) {
        initBenefitContent(content, level);
      }
    });
    moveBenefitSwiperToCurrentLevel(level);
  };

  const initBenefitContent = function (content, level) {
    const buttonMore = content.querySelector(SELECTORS.buttonMore);
    const buttonLess = content.querySelector(SELECTORS.buttonLess);
    if (shouldUnlock(content, level)) {
      content.classList.add("mod--unlock");
    }
    buttonMore?.addEventListener("click", () => {
      content.classList.remove("mod--reverse-flip");
      content.classList.add("mod--flip");
    });
    buttonLess?.addEventListener("click", () => {
      content.classList.remove("mod--flip");
      content.classList.add("mod--reverse-flip");
    });
  };

  const shouldUnlock = function (content, level) {
    if (!level) {
      return false;
    }
    const isEssential = content.classList.contains("mod--essential");
    const isSpecial = content.classList.contains("mod--special");
    const isUnique = content.classList.contains("mod--unique");
    const unlockConditions = {
      essential: isEssential,
      special: isEssential || isSpecial,
      unique: isEssential || isSpecial || isUnique,
    };
    return unlockConditions[level] || false;
  };

  const moveBenefitSwiperToCurrentLevel = function (level) {
    if (!level) {
      return;
    }
    const swiperCarousels = document.querySelectorAll(SELECTORS.swiperComponent);
    if (!swiperCarousels.length) {
      return;
    }
    swiperCarousels.forEach((carousel) => {
      const allSlides = carousel.querySelectorAll(SELECTORS.content);
      let slideIndex = -1;
      allSlides.forEach((slide, index) => {
        if (slide.classList.contains(`mod--${level}`) && slideIndex === -1) {
          slideIndex = index;
        }
      });
      if (slideIndex !== -1 && carousel.swiper) {
        carousel.swiper.slideTo(slideIndex);
      }
    });
  };

  const checkUserDataAndInit = function () {
    if (bcl.editMode && !bcl.profile?.mapValues?.data) {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybBenefit.css", SELECTORS.dynamicClass);
      return;
    }
    if (bcl.profile?.mapValues?.data) {
      MybBenefit();
    } else {
      setTimeout(checkUserDataAndInit, 500);
    }
  };

  if (document.readyState !== "loading") {
    checkUserDataAndInit();
  } else {
    document.addEventListener("DOMContentLoaded", checkUserDataAndInit, true);
  }
})();
