bcl.c.mybEditProfileV2 = {
  personalElements: {
    form: ".js-form-personal-data",
    name: ".js-input-name",
    surname: ".js-input-surname",
    birthday: ".js-input-birthday",
    phone: ".js-input-phone",
    phonePrefixParent: ".js-phone-parent",
    phonePrefix: ".iti__active",
    address: ".js-input-address",
    postalCode: ".js-input-postal-code",
    province: ".js-input-province",
    city: ".js-input-city",
    country: ".js-select-country",
    countryFlag: ".js-change-flag",
    email: ".js-input-email",
    accordionButtons: ".js-open-accordion",
  },

  modals: {
    activeClass: "tingle-modal--visible",
    successModal: ".js-success-modal",
    errorModal: ".js-error-modal",
  },

  props: {
    dynamicClass: ".c-myb-edit-profile-content-v2.dynamicLoading",
    component: "c-myb-edit-profile-content-v2",
    endpoint: "",
    invalidInput: ".mod--invalidate",
    updateButton: ".js-update-button",
    info: {
      givenName: "string",
      surname: "string",
      phoneNumber: "string",
      phoneCountryPrefix: "string",
      address: "string",
      city: "string",
      postalCode: "string",
      state: "string",
      country: "string",
      birthdayDate: "string",
    },
    inputs: {},
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.mybEditProfileV2.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    const $component = baseFind.getElementsByClassName(bcl.c.mybEditProfileV2.props.component);

    if (!$component || !$component[0]) {
      return;
    }

    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-cMybEditProfV2.css", bcl.c.mybEditProfileV2.props.dynamicClass);

    bcl.c.mybEditProfileV2.props.endpointPostData = $component[0].dataset.urlPostData;
    bcl.c.mybEditProfileV2.props.endpointPost = $component[0].dataset.urlPost;
    bcl.c.mybEditProfileV2.initObserverMybEditProfile($component[0]);
  },

  initObserverMybEditProfile: function ($component) {
    const targetElement = $component?.closest(".block-quest-tab-menu-js");
    if (targetElement) {
      //Check if the element is already visible
      if (!targetElement.classList.contains("hidden")) {
        bcl.c.mybEditProfileV2.initFormInputs($component);
      }else{
        //If not, we will observe the element to check when it's visible
        const observer = new MutationObserver(() => {
          if (!targetElement.classList.contains("hidden")) {
            bcl.c.mybEditProfileV2.initFormInputs($component);
            observer.disconnect();
          }
        });
        observer.observe(targetElement, { attributes: true, attributeFilter: ["class"] });
      }
    } else {
      //FALLBACK IN CASE THE ELEMENTS DOESN'T EXIST
      bcl.c.mybEditProfileV2.initFormInputs($component);
    }
  },

  initFormInputs: function ($component) {
    bcl.c.mybEditProfileV2.setInputs($component);
    setTimeout(() => {
      bcl.c.mybEditProfileV2.addListeners($component);
    }, 250);
  },

  addListeners: function (element) {
    const personalForm = element.querySelector(bcl.c.mybEditProfileV2.personalElements.form);
    const updateButton = element.querySelector(bcl.c.mybEditProfileV2.props.updateButton);
    const inputs = [
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.name),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.surname),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.birthday),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.phone),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.address),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.postalCode),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.province),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.city),
      element.querySelector(bcl.c.mybEditProfileV2.personalElements.country),
    ];
    const originalValues = inputs.map((input) => (input ? input.value : ""));

    const checkForChanges = () => {
      const hasChanges = inputs.some((input, index) => input && input.value !== originalValues[index]);
      if (hasChanges) {
        updateButton.classList.remove("mod--inactive");
      } else {
        updateButton.classList.add("mod--inactive");
      }
    };

    if (personalForm) {
      personalForm.addEventListener("submit", bcl.c.mybEditProfileV2.submitPersonalForm);
    }

    const inputName = element.querySelector(bcl.c.mybEditProfileV2.personalElements.name);
    if (inputName) {
      inputName.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputName, personalForm);
        checkForChanges();
      });
    }

    const inputSurname = element.querySelector(bcl.c.mybEditProfileV2.personalElements.surname);
    if (inputSurname) {
      inputSurname.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputSurname, personalForm);
        checkForChanges();
      });
    }

    const inputBirth = element.querySelector(bcl.c.mybEditProfileV2.personalElements.birthday);
    if (inputBirth) {
      inputBirth.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputBirth, personalForm);
        checkForChanges();
      });
    }

    const inputPhone = element.querySelector(bcl.c.mybEditProfileV2.personalElements.phone);
    if (inputPhone) {
      inputPhone.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputPhone, personalForm);
        checkForChanges();
      });
    }

    const inputAddress = element.querySelector(bcl.c.mybEditProfileV2.personalElements.address);
    if (inputAddress) {
      inputAddress.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputAddress, personalForm);
        checkForChanges();
      });
    }

    const inputPostalCode = element.querySelector(bcl.c.mybEditProfileV2.personalElements.postalCode);
    if (inputPostalCode) {
      inputPostalCode.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputPostalCode, personalForm);
        checkForChanges();
      });
    }

    const inputProvince = element.querySelector(bcl.c.mybEditProfileV2.personalElements.province);
    if (inputProvince) {
      inputProvince.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputProvince, personalForm);
        checkForChanges();
      });
    }

    const inputCity = element.querySelector(bcl.c.mybEditProfileV2.personalElements.city);
    if (inputCity) {
      inputCity.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputCity, personalForm);
        checkForChanges();
      });
    }

    const inputCountry = element.querySelector(bcl.c.mybEditProfileV2.personalElements.country);
    if (inputCountry) {
      inputCountry.addEventListener("input", function () {
        bcl.c.mybEditProfileV2.validateInput(inputCountry, personalForm);
        checkForChanges();
        const flagContainer = element.querySelector(bcl.c.mybEditProfileV2.personalElements.countryFlag);
        flagContainer.innerHTML = "";
        flagContainer.innerHTML = `<span class='iti__flag iti__${inputCountry.value.toLowerCase()}'></span>`;
      });
    }
  },

  validateInput: function (input, form) {
    // eslint-disable-next-line no-useless-escape
    const formato = /[`!¡¿¬¨@#$%^&*()_+\=\[\]{};':"\\|<>\/?~€]/;
    if (formato.test(input.value) || input.value.startsWith(" ") || input.value.startsWith("-") || input.value.startsWith(",") || input.value.startsWith(".")) {
      bcl.u.addClass(input, "mod--invalidate");
      bcl.u.addClass(form, "errorValidationForm");
    } else if (!form.classList.contains("errorValidationForm")) {
      bcl.u.removeClass(input, "mod--invalidate");
      bcl.u.removeClass(form, "errorValidationForm");
    }
  },

  submitPersonalForm: function (event) {
    event.preventDefault();
    const form = event.target;
    const invalidInput = form.querySelector(bcl.c.mybEditProfileV2.props.invalidInput);
    if (!invalidInput) {
      const inputName = form.querySelector(bcl.c.mybEditProfileV2.personalElements.name);
      if (inputName) {
        bcl.c.mybEditProfileV2.validateInput(inputName, form);
      }
      bcl.c.mybEditProfileV2.setValuekey("givenName", bcl.c.mybEditProfileV2.props.inputs.name?.value);

      const inputSurname = form.querySelector(bcl.c.mybEditProfileV2.personalElements.surname);
      if (inputSurname) {
        bcl.c.mybEditProfileV2.validateInput(inputSurname, form);
      }
      bcl.c.mybEditProfileV2.setValuekey("email", bcl.c.mybEditProfileV2.props.inputs.email?.value);

      bcl.c.mybEditProfileV2.setValuekey("surname", bcl.c.mybEditProfileV2.props.inputs.surname?.value);

      bcl.c.mybEditProfileV2.setValuekey("birthdayDate", bcl.c.mybEditProfileV2.props.inputs.birthday?.value);

      bcl.c.mybEditProfileV2.setValuekey("phoneNumber", bcl.c.mybEditProfileV2.props.inputs.phone?.value);

      const phonePrefix = bcl.c.mybEditProfileV2.props.inputs.phone.parentElement.querySelector(bcl.c.mybEditProfileV2.personalElements.phonePrefix);
      bcl.c.mybEditProfileV2.setValuekey("phoneCountryPrefix", phonePrefix?.dataset.dialCode);

      const inputAddress = form.querySelector(bcl.c.mybEditProfileV2.personalElements.address);
      if (inputAddress) {
        bcl.c.mybEditProfileV2.validateInput(inputAddress, form);
      }
      bcl.c.mybEditProfileV2.setValuekey("address", bcl.c.mybEditProfileV2.props.inputs.address?.value);

      const inputPostalCode = form.querySelector(bcl.c.mybEditProfileV2.personalElements.postalCode);
      if (inputPostalCode) {
        bcl.c.mybEditProfileV2.validateInput(inputPostalCode, form);
      }
      bcl.c.mybEditProfileV2.setValuekey("postalCode", bcl.c.mybEditProfileV2.props.inputs.postalCode?.value);

      const inputProvince = form.querySelector(bcl.c.mybEditProfileV2.personalElements.province);
      if (inputProvince) {
        bcl.c.mybEditProfileV2.validateInput(inputProvince, form);
      }
      bcl.c.mybEditProfileV2.setValuekey("state", bcl.c.mybEditProfileV2.props.inputs.province?.value);

      const inputCity = form.querySelector(bcl.c.mybEditProfileV2.personalElements.city);
      if (inputCity) {
        bcl.c.mybEditProfileV2.validateInput(inputCity, form);
      }
      bcl.c.mybEditProfileV2.setValuekey("city", bcl.c.mybEditProfileV2.props.inputs.city?.value);

      bcl.c.mybEditProfileV2.setValuekey("country", bcl.c.mybEditProfileV2.props.inputs.country?.value.toLowerCase());

      if (!form.classList.contains("errorValidationForm")) {
        bcl.c.mybEditProfileV2.updateApiData(form);
      } else {
        bcl.c.mybEditProfileV2.errorSubmit(event, form);
      }
    }
  },

  setValuekey: function (key, value) {
    if (!key || !value) {
      return;
    }
    bcl.c.mybEditProfileV2.props.info[key] = value;
  },

  updateApiData: function (form) {
    bcl.ajax.postRequest({
      data: bcl.c.mybEditProfileV2.props.info,
      url: bcl.c.mybEditProfileV2.props.endpointPost,
      callback: (e) => {
        bcl.c.mybLoyaltyUserInfo.cleanCache();
        bcl.c.mybEditProfileV2.updateNames();
        bcl.c.mybEditProfileV2.successSubmit(e, form);
      },
      errorCallback: (e) => {
        bcl.c.mybEditProfileV2.errorSubmit(e, form);
      },
    });
  },

  updateNames: function () {
    const name = bcl.c.mybEditProfileV2.props.info?.givenName;
    const surname = bcl.c.mybEditProfileV2.props.info?.surname;
    const namePlaces = document.querySelectorAll(".js-myb-update-name");
    const completeNamePlaces = document.querySelectorAll(".js-myb-update-complete-name");
    namePlaces.forEach((place) => {
      place.innerHTML = name;
    });
    completeNamePlaces.forEach((place) => {
      place.innerHTML = name + " " + surname;
    });
  },
  getApiData: function (form) {
    bcl.u.addBhgLoading(form);
    bcl.c.mybLoyaltyUserInfo.requestUserProfileData(
      (e) => {
        bcl.c.mybEditProfileV2.setApiData(e.responseText, form);
        bcl.u.removeBhgLoading(form);
      },
      (e) => {
        bcl.c.mybEditProfileV2.errorSubmit(e, form);
        bcl.u.removeBhgLoading(form);
      },
    );
  },

  setApiData: function (responseText, form) {
    const data = JSON.parse(responseText);
    bcl.c.mybEditProfileV2.props.data = data;
    if (data.givenName) {
      bcl.c.mybEditProfileV2.props.inputs.name.value = data.givenName;
    }
    if (data.surname) {
      bcl.c.mybEditProfileV2.props.inputs.surname.value = data.surname;
    }
    const birthday = bcl.c.mybEditProfileV2.props.inputs.form.querySelectorAll(bcl.c.mybEditProfileV2.personalElements.birthday);
    bcl.c.mybEditProfileV2.props.inputs.birthday = birthday[0];
    if (data.birthdayDate && data.birthdayDate !== "1900-01-01") {
      birthday[0].value = data.birthdayDate;
      if (birthday[1]) {
        birthday[1].value = data.birthdayDate.split("-").reverse().join("/");
      }
    }
    if (data.phoneNumber && data.phoneNumber !== "000000000") {
      bcl.c.mybEditProfileV2.props.inputs.phone.value = data.phoneNumber;
    }

    setTimeout(bcl.c.mybEditProfileV2.selectedFlag, 300);

    if (data.email) {
      bcl.c.mybEditProfileV2.props.inputs.email.value = data.email;
    }

    if (data.address) {
      bcl.c.mybEditProfileV2.props.inputs.address.value = data.address;
    }
    if (data.postalCode) {
      bcl.c.mybEditProfileV2.props.inputs.postalCode.value = data.postalCode;
    }
    if (data.state) {
      bcl.c.mybEditProfileV2.props.inputs.province.value = data.state;
    }
    if (data.city) {
      bcl.c.mybEditProfileV2.props.inputs.city.value = data.city;
    }
    if (data.country) {
      bcl.c.mybEditProfileV2.props.inputs.country.value = data.country.toUpperCase();

      const flagContainer = form.querySelector(bcl.c.mybEditProfileV2.personalElements.countryFlag);
      flagContainer.innerHTML = "";
      flagContainer.innerHTML = `<span class='iti__flag iti__${data.country.toLowerCase()}'></span>`;
    }
  },

  selectedFlag: function () {
    const prefix = bcl.c.mybEditProfileV2.props.inputs.phonePrefix.querySelector(`.iti__country.iti__standard[data-dial-code="${bcl.c.mybEditProfileV2.props.data.phoneCountryPrefix}"]`);
    const selected = bcl.c.mybEditProfileV2.props.inputs.phonePrefix.querySelector(".iti__selected-flag");
    if (prefix && selected) {
      const newFlag = prefix ? prefix.querySelector(".iti__flag") : null;
      const newName = prefix ? prefix.querySelector(".iti__country-name") : null;
      const dialCode = prefix ? prefix.querySelector(".iti__dial-code") : null;
      const selectedFlag = selected ? selected.firstElementChild : null;
      if (newFlag && newName && dialCode && selectedFlag) {
        selected.title = newName.innerHTML + ": " + dialCode;
        selectedFlag.classList = newFlag.classList;
      }
    }
  },

  setInputs: function (form) {
    if (form) {
      bcl.c.mybEditProfileV2.props.inputs.form = form;
    }

    const name = form.querySelector(bcl.c.mybEditProfileV2.personalElements.name);
    if (name) {
      bcl.c.mybEditProfileV2.props.inputs.name = name;
    }

    const surname = form.querySelector(bcl.c.mybEditProfileV2.personalElements.surname);
    if (surname) {
      bcl.c.mybEditProfileV2.props.inputs.surname = surname;
    }

    const birthday = form.querySelector(bcl.c.mybEditProfileV2.personalElements.birthday);
    if (birthday) {
      bcl.c.mybEditProfileV2.props.inputs.birthday = birthday;
    }

    const phone = form.querySelector(bcl.c.mybEditProfileV2.personalElements.phone);
    if (phone) {
      bcl.c.mybEditProfileV2.props.inputs.phone = phone;
    }

    const phonePrefix = form.querySelector(bcl.c.mybEditProfileV2.personalElements.phonePrefixParent);
    if (phonePrefix) {
      bcl.c.mybEditProfileV2.props.inputs.phonePrefix = phonePrefix;
    }

    const email = form.querySelector(bcl.c.mybEditProfileV2.personalElements.email);
    if (email) {
      bcl.c.mybEditProfileV2.props.inputs.email = email;
    }

    const address = form.querySelector(bcl.c.mybEditProfileV2.personalElements.address);
    if (address) {
      bcl.c.mybEditProfileV2.props.inputs.address = address;
    }

    const postalCode = form.querySelector(bcl.c.mybEditProfileV2.personalElements.postalCode);
    if (postalCode) {
      bcl.c.mybEditProfileV2.props.inputs.postalCode = postalCode;
    }

    const province = form.querySelector(bcl.c.mybEditProfileV2.personalElements.province);
    if (province) {
      bcl.c.mybEditProfileV2.props.inputs.province = province;
    }

    const city = form.querySelector(bcl.c.mybEditProfileV2.personalElements.city);
    if (city) {
      bcl.c.mybEditProfileV2.props.inputs.city = city;
    }

    const country = form.querySelector(bcl.c.mybEditProfileV2.personalElements.country);
    if (country) {
      bcl.c.mybEditProfileV2.props.inputs.country = country;
    }
    bcl.c.mybEditProfileV2.getApiData(form);
  },

  successSubmit: function (e, form) {
    const successModal = form.querySelector(bcl.c.mybEditProfileV2.modals.successModal);
    const closeModal = successModal.querySelector(".js-close-modal");
    closeModal.addEventListener("click", () => {
      successModal.classList.remove(bcl.c.mybEditProfileV2.modals.activeClass);
    });
    successModal.classList.add(bcl.c.mybEditProfileV2.modals.activeClass);
  },

  errorSubmit: function (e, form) {
    const errorModal = form.querySelector(bcl.c.mybEditProfileV2.modals.errorModal);
    const closeModal = errorModal.querySelector(".js-close-modal");
    closeModal.addEventListener("click", () => {
      errorModal.classList.remove(bcl.c.mybEditProfileV2.modals.activeClass);
    });
    errorModal.classList.add(bcl.c.mybEditProfileV2.modals.activeClass);
  },
};
