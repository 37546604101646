(() => {
  const MybBannerAnimated = () => {
    const selectors = {
      dynamicClass: ".c-myb-banner-animated.dynamicLoading",
      component: ".myb-banner-animated",
      content: ".js-banner-animated",
      bannerImage: ".js-banner-animated-image",
      image: ".cmp-image__image",
    };

    const applyMaskImage = (bannerImage, src) => {
      if (!src) {
        return;
      }
      const maskUrl = `url("${src}")`;
      bannerImage.style.maskImage = maskUrl;
      bannerImage.style.webkitMaskImage = maskUrl;
      bannerImage.style.maskRepeat = "no-repeat";
      bannerImage.style.webkitMaskRepeat = "no-repeat";
      bannerImage.style.maskSize = "cover";
      bannerImage.style.webkitMaskSize = "cover";
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const content = entry.target;
        const isMobile = window.innerWidth < 1025;

        if (entry.isIntersecting && isMobile) {
          content.classList.add("mod--number");
        } else {
          content.classList.remove("mod--number");
        }
      });
    };

    const createIntersectionObserver = (content) => {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(handleIntersection, options);
      observer.observe(content);
    };

    const handleImageLoad = (bannerImage, content, bannerImageContent) => {
      applyMaskImage(bannerImage, bannerImageContent.src);
      createIntersectionObserver(content);
    };

    const observeImage = (bannerImage, content) => {
      const bannerImageContent = bannerImage.querySelector(selectors.image);
      if (!bannerImageContent) {
        return;
      }

      if (bannerImageContent.complete && bannerImageContent.naturalWidth > 0) {
        handleImageLoad(bannerImage, content, bannerImageContent);
      } else {
        bannerImageContent.addEventListener("load", () => handleImageLoad(bannerImage, content, bannerImageContent), { once: true });
      }
    };

    const init = () => {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybBannerAnimated.css", selectors.dynamicClass);

      const components = document.querySelectorAll(selectors.component);

      components.forEach((element) => {
        const content = element.querySelector(selectors.content);
        const bannerImage = element.querySelector(selectors.bannerImage);

        if (content && bannerImage) {
          observeImage(bannerImage, content);
        }
      });
    };

    init();
  };

  if (document.readyState !== "loading") {
    MybBannerAnimated();
  } else {
    document.addEventListener("DOMContentLoaded", MybBannerAnimated, true);
  }
})();
